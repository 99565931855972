html {
  text-align: center;
  position: fixed;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.box {
  display: flex;
  align-items:center;
  margin-top: 15px;
  margin-left: 15px;
  color:#fff;
  font-size: 20px;
  height: 44px;
  width: 44px;
  transform: rotateY(0deg) rotate(0deg); /* needs Y at 0 deg to behave properly*/
  transition: transform 2s;
  background-image: url(./assets/punk-44x44.png);
}

.box:hover {
  animation: App-logo-spin infinite 5s linear;
}

.logo-text {
  margin-left: 10px;
}

.header {
  background-color: #48d1cc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 5rem;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 374px) {
  .header-links {
    display: none !important;
  }
  .header {
    justify-content: center;
  }
  .box {
    margin-left: 0;
  }
}

.header-links {
  color:#fff;
  display: flex;
  justify-content:flex-end;
  flex-direction: row;
}

.header-link-item {
  margin-left: 15px;
  margin-right: 20px;
  text-decoration: none;

  background: -webkit-linear-gradient(45deg, #2C3E50, #34495E 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.body {
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: #2C3E50;
  height: calc(100vh - 10rem);
  width: 100vw;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.footer {
  background-color: #48d1cc;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 5rem;
  display: flex;
}

/* .header:before {
    content: '';
    position: fixed;
    top: 5px;
    right: 5px;
    bottom: calc(100% - 5rem + 5px);
    left: 5px;
    border: 2px solid #ECF0F1;
}

.footer:before {
  content: '';
  position: fixed;
  top: calc(100% + 5px - 5rem);
  right: 5px;
  bottom: 5px;
  left: 5px;
  border: 2px solid #ECF0F1;
} */

.link {
  border: 1px solid #2C3E50;
  width: 44px;
  height: 44px;
  box-shadow: inset 0 0 0 #2C3E50;
  transition: all .3s ease-in-out;
  z-index: 3;
  text-align: center;
  display: block;
  justify-content: center;
}

.social-link-item-github {
  background-position: -40px 0;
  width: 18px;
}

.social-link-item-linkedin {
  background-position: -20px 0;
  width: 18px;
}

.social-links {
  position: fixed;
  width: 475px;
  left: 50%;
  bottom: 3px;
  margin-left: -237px;
  padding: 0;
  list-style-type: none;
}

.social-links > li {
  display: inline-block;
}

.social-link-item {
  display: inline-block;
  background-image: url(./assets/icons-sprite.png);
  height: 20px;
  background-size: 123px 20px;
  text-indent: -9999px;
  margin-left: 15px;
  -webkit-transition: all .1s ease-in;
}

.email-link-outer {
  display: block !important;
}

.email-link {
  color:#fff;
  font-size: 15px;
  margin-left: 15px;
  text-decoration: none;
  -webkit-transition: opacity .1s ease-in;
}

@media only screen and (min-width: 900px) {
  .text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .name {
    font-size: 48px !important;
    margin-left: 30px;
  }

  .description {
    font-size: 32px !important;
    margin-left: 15px;
    margin-top: 10px;
  }
}

.text-wrapper {
  z-index: 3;
}

@supports not (-webkit-overflow-scrolling: touch) {
  .name {
    font-size: 36px;
    font-weight: 800;
    color: #fff;
  }
  
  .description {
    font-size: 24px;
    margin-left: 10px;
    margin-right: 10px;
    color:#48d1cc;
  }
}

.name {
  font-size: 30px;
  color: #fff;
}

.description {
  font-size: 20px;
  color: #48d1cc;
}

.triangle-pattern {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(./assets/triangles.png);
  background-size: cover;
  background-position: center;
  opacity: .06;
}

.blockchain-icon {
  margin-bottom: 5rem;
}


